import React from "react";
import { graphql } from "gatsby";

import TermlyPage from "~/components/TermlyPage";

const Disclaimer = ({ data, location }) => {
  return <TermlyPage data={data} location={location} />;
};

export const query = graphql`
  {
    contentfulPage(slug: { eq: "disclaimer" }) {
      title
      termlyScriptUrl
      termlyId
    }
  }
`;

export default Disclaimer;
